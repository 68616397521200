/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom rules */
/* Color picker */
.react-colorful {
  width: auto !important; /* Automatic dimensions */
  /* height: auto !important; */
}
.react-colorful__hide-saturation > .react-colorful__saturation {
  display: none; /* Hide the saturation selection (only hue) */
}
.react-colorful__saturation {
  border-radius: 5px !important;
  margin-bottom: 4px;
}
.react-colorful__hue {
  border-radius: 5px !important;
}

/* Tooltip z-index */
.tooltip {
  z-index: 2; /* Otherwise tooltips are hidden behind other elements */
}
